import { useEffect } from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { InputLabel } from '@Root/Components'
import ReCAPTCHA from 'react-google-recaptcha'
import { booleanOptions } from '@Root/configs'

export const Declaration = ({ onChange, data, isMobile, errors }) => {
	const { accrediting_body, Date } = data

	const isTest = process.env.NODE_ENV === 'test'

	useEffect(() => {
		if (isTest) {
			onChange('captchaCode')('121221')
		}
	}, [isTest])

	return (
		<div className={styles.section}>
			<SectionTitle id={"declaration"}>10. Declaration</SectionTitle>
			<div className={`${styles.wrapper} ${styles.center_items}`}>
				<div>
					<p>
						I confirm that all the information given in this application is accurate and agree to my personal data being processed by Teid and its accrediting
						body.*
					</p>
					<div className={styles.fields_row}>
						<Field
							label={'Date'}
							field={'datePicker'}
							style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '240px' : '288px' }}
							value={Date}
							onChange={onChange('Date')}
							maxLength={100}
							fieldClass={'main'}
						/>
						<Field
							hideLabel
							options={booleanOptions}
							field={'radioInput'}
							style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
							value={accrediting_body ? 1 : 2}
							onChange={(value) => onChange('accrediting_body')(value === 1)}
							isInline
							fieldClass={'single'}
						/>{' '}
					</div>
					<p className={`${styles.submit_note} ${!errors.accrediting_body ? '' : styles.invalid}`}>
						To submit or save your application you need to agree to the Declaration by selecting the Yes button above AND enter the date.
					</p>
				</div>
				{!isTest ? (
					<div>
						<div className={styles.captcha}>
							<InputLabel style={{ color: errors.captchaCode ? 'red' : '#111111', width: 280 }} text='Please complete the security box below' />
							<ReCAPTCHA sitekey='6Ldxjo8UAAAAAOj4-f2ppg4aGe1O6Hqbz0PTPxYb' onChange={onChange('captchaCode')} />
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
			<div className={styles.important_note}>
				<span className={styles.important}>IMPORTANT:</span>{' '}
				<span className={styles.note}>
					Please click the 'Save' button if you wish to complete your application later - this will generate a login token which will be sent to your email
					address.
					<strong> A valid token is required to return to your application.</strong> Or, to submit your finished application click the 'Finish and Submit'
					button.
				</span>
			</div>
		</div>
	)
}
