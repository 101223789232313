import React from 'react'
import styles from './RadioInputGroup.module.scss'
import PropTypes from 'prop-types'

const RadioInputGroup = ({ options, currentValue, changeHandler, isInline, labelStyle, firstWordIsBold, byLabel }) => {
	return (
		<div className={`${styles.RadioInputGroup} ${isInline ? styles.inline : ''}`}>
			{options.map(({ value, label }) => (
				<div className={styles.wrapper} onClick={() => changeHandler(value)} key={value} data-test-id='radio'>
					<div className={styles.input}>{(byLabel ? label : value) === currentValue && <div className={styles.checkmark} />}</div>
					<div className={styles.label} style={labelStyle}>
						{!firstWordIsBold ? (
							label
						) : (
							<>
								<b>{label.split(' ')[0]}</b>&nbsp;
								{label.split(' ').slice(1).join(' ')}
							</>
						)}
					</div>
				</div>
			))}
		</div>
	)
}

RadioInputGroup.propTypes = {
	options: PropTypes.array.isRequired,
	currentValue: PropTypes.string.isRequired,
	changeHandler: PropTypes.func.isRequired,
	isInline: PropTypes.bool,
	labelStyle: PropTypes.object,
	firstWordIsBold: PropTypes.bool,
}

export default RadioInputGroup
