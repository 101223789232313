import React from 'react'
import styles from './style.module.scss'
import RadioInputGroup from '@Root/Components/RadioInputGroup/RadioInputGroup'
import DatePicker from '@Root/Components/DatePicker/DatePicker'
import TextArea from '@Root/Components/TextArea/TextArea'
import { Select } from '@Root/Components/Select'
import TextInput from '@Root/Components/TextInput/TextInput'
import { InputLabel } from '@Root/Components'
import MultiSelectItems from '@Root/Components/MultiSelectItems/MultiSelectItems'
import MultiSelect from '@khanacademy/react-multi-select'
import FormTable from '@Root/Components/FormTable/FormTable'
import { LazyLoadSelect } from '@Root/Components/LazyLoadSelect'
export const Field = ({
	onChange,
	maxLength = 255,
	classNames,
	error = '',
	value = '',
	label = '',
	isRequired = false,
	description = '',
	onOpenDescriptionModal,
	style = {},
	placeholder = '',
	isDisabled,
	field = 'textField',
	options,
	optionsStyle,
	optionsSliceQuantity,
	restriction,
	autoHeight = false,
	isInline,
	fieldClass,
	hasSelectAll = false,
	disableSearch = false,
	overrideStrings,
	rows,
	onAddRow,
	hideLabel = false,
	byLabel = false,
	optionsHandler,
	fetchOptionsHandler,
	maxVisibleOptionsQuantity,
	changeHandler,
	fetchLabelHandler,
	id,
}) => {
	const renderField = () => {
		switch (field) {
			case 'textField':
				return (
					<TextInput
						classNames={classNames}
						value={value}
						changeHandler={onChange}
						isDisabled={isDisabled}
						maxLength={maxLength}
						error={error}
						style={style}
						placeholder={placeholder}
						restriction={restriction}
						id={id}
					/>
				)
			case 'select':
				return (
					<Select
						inputClassNames={classNames}
						inputStyle={style}
						style={style}
						options={options}
						value={value}
						changeHandler={onChange}
						error={error}
						isDisabled={isDisabled}
						placeholder={placeholder}
						optionsStyle={optionsStyle}
						optionsSliceQuantity={optionsSliceQuantity}
					/>
				)
			case 'textArea':
				return (
					<TextArea
						classNames={classNames}
						value={value}
						changeHandler={onChange}
						isDisabled={isDisabled}
						maxLength={maxLength}
						error={error}
						style={style}
						placeholder={placeholder}
						autoHeight={autoHeight}
					/>
				)
			case 'datePicker':
				return (
					<DatePicker
						classNames={classNames}
						inputStyle={style}
						value={value}
						changeHandler={onChange}
						error={error}
						isDisabled={isDisabled}
						placeholder={placeholder}
					/>
				)
			case 'multiSelect':
				return (
					<MultiSelect
						ItemRenderer={MultiSelectItems}
						options={options}
						selected={value}
						disableSearch={disableSearch}
						hasSelectAll={hasSelectAll}
						onSelectedChanged={onChange}
						overrideStrings={overrideStrings}
					/>
				)
			case 'asyncSelect':
				return (
					<LazyLoadSelect
						options={options}
						disableSearch={disableSearch}
						optionsHandler={optionsHandler}
						error={error}
						maxVisibleOptionsQuantity={maxVisibleOptionsQuantity}
						isDisabled={isDisabled}
						fetchOptionsHandler={fetchOptionsHandler}
						value={value}
						changeHandler={changeHandler}
						fetchLabelHandler={fetchLabelHandler}
					/>
				)
			case 'radioInput':
				return <RadioInputGroup options={options} currentValue={value} isInline={isInline} isDisabled={isDisabled} changeHandler={onChange} byLabel={byLabel} />
			case 'formTable':
				return <FormTable colomns={options} rows={rows} onTxtAreaInput={onChange} onAddBtn={onAddRow} />
			default:
				return <div>Field not found</div>
		}
	}
	return (
		<div
			className={`${field === 'multiCheckbox' ? styles.inputSingleColumnWrapper : styles.inputDoubleColumnWrapper} ${styles.inputWrapper} ${
				styles[fieldClass]
			}`}
		>
			{!hideLabel && <InputLabel text={label} hasAsterisk={isRequired} description={description} onOpenDescription={onOpenDescriptionModal} hideHeight />}
			{renderField()}
		</div>
	)
}
