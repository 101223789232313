import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { outcomeBapOptions, booleanOptions, progressThroughNationalDiscernmentProcessOptions } from '@Root/configs'

export const MinisterialDetails = ({ onChange, data, errors = {}, isMobile, options, onFindDiocese, onFindDioceseById, fetchLabelHandler }) => {
	const {
		name_of_current_church,
		sending_diocese,
		name_of_your_DDO,
		date_of_last_meeting_with_DDO,
		email_contact_for_your_DDO,
		phone_contact_fo_your_DDO,
		progress_stage,
		progress_stage_date,
		outcome_of_BAP,
		outcome_pioneer_minister,
		church_of_england,
	} = data

	return (
		<div className={styles.section}>
			<SectionTitle id={"ministerial_details"}>5. Ministerial details</SectionTitle>
			<div className={styles.fields}>
				<p>(If you are not applying for ordination in the Church of England, please move straight on to section 6)</p>
				<Field
					label={'Name of current church'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
					value={name_of_current_church}
					onChange={onChange('name_of_current_church')}
					maxLength={100}
					fieldClass={'long'}
				/>{' '}
				<Field
					label={'Sending diocese'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '287px' }}
					field={'asyncSelect'}
					value={sending_diocese}
					onChange={onChange('sending_diocese')}
					options={options.dioceses}
					fieldClass={'long'}
					maxVisibleOptionsQuantity={5}
					fetchOptionsHandler={onFindDiocese}
					changeHandler={onFindDioceseById}
					fetchLabelHandler={fetchLabelHandler}
				/>{' '}
				<Field
					label={'Name of your DDO'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
					value={name_of_your_DDO}
					onChange={onChange('name_of_your_DDO')}
					maxLength={100}
					fieldClass={'long'}
				/>{' '}
				<Field
					label={'Date of last meeting with DDO'}
					field={'datePicker'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '288px' }}
					value={date_of_last_meeting_with_DDO}
					onChange={onChange('date_of_last_meeting_with_DDO')}
					maxLength={100}
					fieldClass={'long'}
				/>
				{church_of_england === 1 && (
					<div id={errors.email_contact_for_your_DDO ? 'scrollHere' : null}>
						<Field
							label={'Email contact for your DDO'}
							field={'textField'}
							//Note: 20px - right and left padding of the field
							style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
							value={email_contact_for_your_DDO}
							onChange={onChange('email_contact_for_your_DDO')}
							maxlength={100}
							isRequired
							error={errors.email_contact_for_your_DDO}
							fieldClass={'long'}
							id={'email'}
						/>
					</div>
				)}
				<Field
					label={'Phone contact for your DDO'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
					value={phone_contact_fo_your_DDO}
					onChange={onChange('phone_contact_fo_your_DDO')}
					maxlength={100}
					fieldClass={'long'}
					restriction={'digits'}
					id='phone'
				/>
				<Field
					label={'Progress through national discernment process'}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '170px' }}
					value={progress_stage}
					options={progressThroughNationalDiscernmentProcessOptions}
					onChange={onChange('progress_stage')}
					fieldClass={'single'}
					isInline
				/>
				{progress_stage > 1 && (
					<Field
						label={`Progress stage ${progress_stage === 2 ? ' 1 ' : '2'} booked date`}
						field={'datePicker'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100% ' : '288px' }}
						value={progress_stage_date}
						onChange={onChange('progress_stage_date')}
						fieldClass={'long'}
					/>
				)}
				<Field
					label={'Outcome of stage 2'}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '170px' }}
					value={outcome_of_BAP}
					options={outcomeBapOptions}
					onChange={onChange('outcome_of_BAP')}
					fieldClass={'single'}
					isInline
				/>
				<Field
					label={'Are you going through selection with the intention of being an Ordained Pioneer Minister?'}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '170px' }}
					value={outcome_pioneer_minister}
					options={[...booleanOptions, { value: 3, label: 'Unknown' }]}
					onChange={onChange('outcome_pioneer_minister')}
					fieldClass={'single'}
					isInline
				/>
			</div>
		</div>
	)
}
